import { useState, useEffect } from 'react';

export function useKeyPress(targetKey: string) {
  const [isKeyPressed, setIsKeyPressed] = useState(false);

  function downHandler({ key }: KeyboardEvent) {
    if (key === targetKey) {
      setIsKeyPressed(true);
    }
  }

  function upHandler({ key }: KeyboardEvent) {
    if (key === targetKey) {
      setIsKeyPressed(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [targetKey]);

  return isKeyPressed;
}
