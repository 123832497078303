import { Canvas as ThreeCanvas } from '@react-three/fiber';
import { useTexture } from '@react-three/drei';

import { PlaneTexture } from '../../data/textures';

import { Scene } from '../Scene/Scene';

export function Canvas({
  open,
  centerPoint,
  texture,
  color,
}: {
  open: boolean;
  centerPoint: {
    x: number;
    y: number;
  };
  texture: PlaneTexture;
  color?: string;
}) {
  return (
    <>
      <ThreeCanvas camera={{ position: [0, 0, 2.5], far: 10 }}>
        <Scene
          open={open}
          centerPoint={centerPoint}
          texture={texture}
          color={color}
        />
      </ThreeCanvas>
    </>
  );
}

useTexture.preload('./textures/N20190.png');
useTexture.preload('./textures/N20190_height.png');
useTexture.preload('./textures/N20190_normals.png');

useTexture.preload('./textures/T1082.png');
useTexture.preload('./textures/T1082_height.png');
useTexture.preload('./textures/T1082_normals.png');

useTexture.preload('./textures/T1266.png');
useTexture.preload('./textures/T1266_height.png');
useTexture.preload('./textures/T1266_normals.png');

useTexture.preload('./textures/T13890.png');
useTexture.preload('./textures/T13890_height.png');
useTexture.preload('./textures/T13890_normals.png');

useTexture.preload('./textures/T14495.png');
useTexture.preload('./textures/T14495_height.png');
useTexture.preload('./textures/T14495_normals.png');

useTexture.preload('./textures/T14530.png');
useTexture.preload('./textures/T14530_height.png');
useTexture.preload('./textures/T14530_normals.png');

useTexture.preload('./textures/T1504.png');
useTexture.preload('./textures/T1504_height.png');
useTexture.preload('./textures/T1504_normals.png');

useTexture.preload('./textures/T15327.png');
useTexture.preload('./textures/T15327_height.png');
useTexture.preload('./textures/T15327_normals.png');

useTexture.preload('./textures/T1715.png');
useTexture.preload('./textures/T1715_height.png');
useTexture.preload('./textures/T1715_normals.png');

useTexture.preload('./textures/T18842_1.png');
useTexture.preload('./textures/T18842_1_height.png');
useTexture.preload('./textures/T18842_1_normals.png');

useTexture.preload('./textures/T24719_297.png');
useTexture.preload('./textures/T24719_297_height.png');
useTexture.preload('./textures/T24719_297_normals.png');

useTexture.preload('./textures/T25523_262.png');
useTexture.preload('./textures/T25523_262_height.png');
useTexture.preload('./textures/T25523_262_normals.png');

useTexture.preload('./textures/T2589.png');
useTexture.preload('./textures/T2589_height.png');
useTexture.preload('./textures/T2589_normals.png');

useTexture.preload('./textures/T26661.png');
useTexture.preload('./textures/T26661_height.png');
useTexture.preload('./textures/T26661_normals.png');

useTexture.preload('./textures/T362.png');
useTexture.preload('./textures/T362_height.png');
useTexture.preload('./textures/T362_normals.png');

useTexture.preload('./textures/T3711.png');
useTexture.preload('./textures/T3711_height.png');
useTexture.preload('./textures/T3711_normals.png');

useTexture.preload('./textures/T374.png');
useTexture.preload('./textures/T374_height.png');
useTexture.preload('./textures/T374_normals.png');

useTexture.preload('./textures/T4012.png');
useTexture.preload('./textures/T4012_height.png');
useTexture.preload('./textures/T4012_normals.png');

useTexture.preload('./textures/T420.png');
useTexture.preload('./textures/T420_height.png');
useTexture.preload('./textures/T420_normals.png');

useTexture.preload('./textures/T5171.png');
useTexture.preload('./textures/T5171_height.png');
useTexture.preload('./textures/T5171_normals.png');

useTexture.preload('./textures/T9261.png');
useTexture.preload('./textures/T9261_height.png');
useTexture.preload('./textures/T9261_normals.png');
