type Color = {
  light: boolean;
  hex: string;
};

const colors: Color[] = [
  { light: true, hex: '#FFD200' },
  { light: true, hex: '#CDC6B5' },
  { light: false, hex: '#000000' },
  { light: true, hex: '#F7F4E2' },
  { light: true, hex: '#FFFFFF' },
  { light: false, hex: '#2D1E1E' },
  { light: true, hex: '#B6BDCC' },
  { light: true, hex: '#BACCB6' },
  { light: true, hex: '#E4BFBF' },
];

export { colors };
export type { Color };
