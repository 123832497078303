import { useRef } from 'react';
import { DoubleSide, Mesh, Vector2, Vector3 } from 'three';
import { useThree, useFrame } from '@react-three/fiber';
import { useTexture } from '@react-three/drei';
import { useControls } from 'leva';
import { motion } from 'framer-motion-3d';

import { PlaneTexture } from '../../data/textures';

export function ImagePlane({
  texture,
  open = false,
  debug = false,
  xOffset,
  centerPoint,
}: {
  texture: PlaneTexture;
  debug: boolean;
  open: boolean;
  xOffset: number;
  centerPoint: {
    x: number;
    y: number;
  };
}) {
  const meshRef = useRef<Mesh | null>(null);
  const timerRef = useRef<number>(0);
  const pointerPositionRef = useRef<Vector2>(new Vector2(0, 0));
  const { viewport, size } = useThree();

  const [map, normalMap, displacementMap] = useTexture([
    '/textures/' + texture.map,
    '/textures/' + texture.normals,
    '/textures/' + texture.height,
  ]);

  const {
    enableDisplacement,
    enableNormals,
    normalScale,
    displacementScale,
    metalness,
    roughness,
    wireframe,
    rotationX,
    rotationY,
  } = useControls(
    'Object',
    {
      enableDisplacement: {
        value: true,
        label: 'Displacement',
      },
      enableNormals: {
        value: true,
        label: 'Normals',
      },
      normalScale: {
        value: {
          x: 1,
          y: -1,
        },
        label: 'Normal Scale',
      },
      displacementScale: {
        value: 0.15,
        min: 0,
        max: 1,
        step: 0.01,
        label: 'Displacement Scale',
      },
      metalness: { value: 0.75, min: 0, max: 1, label: 'Metalness' },
      roughness: { value: 0.5, min: 0, max: 1, label: 'Roughness' },
      wireframe: false,
      rotationX: {
        value: 0.1,
        min: 0,
        max: 1,
        step: 0.01,
        label: 'X rotation',
      },
      rotationY: {
        value: 0.1,
        min: 0,
        max: 1,
        step: 0.01,
        label: 'Y rotation',
      },
    },
    {
      render: (get) => get('debug'),
      collapsed: true,
    }
  );

  useFrame(({ pointer, clock }, delta) => {
    if (!meshRef.current || !open) {
      return;
    }

    const { elapsedTime } = clock;

    if (pointerPositionRef.current.equals(pointer)) {
      timerRef.current += delta;
    } else {
      timerRef.current = 0;
      pointerPositionRef.current = pointer.clone();
    }

    let strength = 1;
    let nextRotation = new Vector3();
    const planeRotation = new Vector3().setFromEuler(meshRef.current.rotation);

    if (Math.floor(timerRef.current) > 5) {
      let nextRotationZ = Math.sin(elapsedTime * 0.5) * 0.1;
      nextRotation.set(0, 0, nextRotationZ);
      strength = 0.05;
    } else {
      const rotationStrengthX = debug
        ? rotationX
        : texture.config?.rotationX || 0.1;
      const rotationStrengthY = debug
        ? rotationY
        : texture.config?.rotationY || 0.1;

      let nextRotationX = -pointer.y * rotationStrengthX;
      let nextRotationY = pointer.x * rotationStrengthY;
      strength = 0.05;
      nextRotation.set(nextRotationX, nextRotationY, 0);
    }

    planeRotation.lerp(nextRotation, strength);
    meshRef.current.rotation.setFromVector3(planeRotation);
  });

  return (
    <motion.group
      position-z={0}
      position-x={xOffset}
      scale={0}
      animate={{
        scale: open ? 1 : 0,
        transition: {
          duration: 0.8,
          ease: [0.25, 0.1, 0.25, 1.0],
        },
      }}
    >
      <mesh ref={meshRef}>
        <planeGeometry
          args={[
            Math.min(
              viewport.width * 0.34,
              viewport.width / (size.width / 400)
            ),
            Math.min(
              viewport.width * 0.34,
              viewport.width / (size.width / 400)
            ),
            200,
            200,
          ]}
        />

        <meshStandardMaterial
          map={map}
          normalMap={enableNormals ? normalMap : null}
          displacementMap={enableDisplacement ? displacementMap : null}
          displacementScale={
            debug ? displacementScale : texture.config?.displacementScale || 0.2
          }
          transparent
          normalScale={
            debug
              ? new Vector2(normalScale.x, normalScale.y)
              : texture.config?.normalScale || new Vector2(1, -1)
          }
          side={DoubleSide}
          metalness={debug ? metalness : texture.config?.metalness || 0.4}
          roughness={debug ? roughness : texture.config?.roughness || 0.6}
          wireframe={wireframe}
        />
      </mesh>
    </motion.group>
  );
}
