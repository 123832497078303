import { MathUtils, Vector2, Vector3 } from 'three';
import { useEffect, useRef, useMemo, useState } from 'react';
import { PointLightHelper, PointLight, Group, Mesh } from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { createNoise3D } from 'simplex-noise';
import { OrbitControls, useHelper } from '@react-three/drei';
import { useControls, folder } from 'leva';

import { ImagePlane } from '../ImagePlane/ImagePlane';

import { TEXTURES, PlaneTexture } from '../../data/textures';

export function Scene({
  open,
  centerPoint,
  texture,
  color,
}: {
  open: boolean;
  centerPoint: { x: number; y: number };
  texture: PlaneTexture;
  color?: string;
}) {
  const pointLightRef = useRef<PointLight>(null!);
  const pointLightContainerRef = useRef<Group>(null!);
  const sphereRef = useRef<Mesh>(null!);

  const timerRef = useRef<number>(0);
  const pointerPositionRef = useRef<Vector2>(new Vector2(0, 0));

  const [xOffset, setXOffset] = useState(0);
  const { viewport, size, camera, pointer } = useThree();

  const noise = useMemo(() => createNoise3D(), []);

  useEffect(() => {
    let x = MathUtils.mapLinear(
      centerPoint.x,
      0,
      size.width,
      -viewport.width * 0.5,
      viewport.width * 0.5
    );

    setXOffset(x);
  }, [centerPoint, viewport, size]);

  const {
    // targetDepth,
    debug,
    enableOrbitControls,
    showGrid,
    showAxes,
    showPointLightHelper,
    ambientIntensity,
    ambientColor,
    pointIntensity,
    debugTexture,
    pointLightColor,
    pointLightDecay,
    pointLightDistance,
  } = useControls({
    debug: { value: false, label: 'Debug' },
    // targetDepth: {
    //   value: 1,
    //   min: -5,
    //   max: 5,
    //   step: 0.1,
    // },
    Scene: folder(
      {
        enableOrbitControls: { value: false, label: 'Orbit controls' },
        showGrid: { value: false, label: 'Grid' },
        showAxes: { value: false, label: 'Axes' },
        showPointLightHelper: { value: false, label: 'Light helper' },
        debugTexture: {
          options: [...Object.keys(TEXTURES)],
          value: Object.keys(TEXTURES)[3],
          label: 'Texure',
        },
      },
      {
        render: (get) => get('debug'),
        collapsed: true,
      }
    ),
    Lighting: folder(
      {
        Ambient: folder(
          {
            ambientIntensity: {
              value: 14,
              min: 0,
              max: 20,
              step: 0.1,
              label: 'Intensity',
            },
            ambientColor: { value: '#fff', label: 'Color' },
          },
          {
            collapsed: true,
          }
        ),
        'Point Light': folder(
          {
            pointIntensity: {
              value: 3,
              min: 0,
              max: 20,
              step: 0.1,
              label: 'Intensity',
            },
            pointLightDistance: {
              value: 2,
              min: 0,
              max: 20,
              step: 0.5,
              label: 'Distance',
            },
            pointLightDecay: {
              value: 2,
              min: 0,
              max: 10,
              step: 0.5,
              label: 'Decay',
            },
            pointLightColor: { value: '#e2e1c1', label: 'Color' },
          },
          {
            collapsed: true,
          }
        ),
      },
      {
        render: (get) => get('debug'),
        collapsed: true,
      }
    ),
  });

  useHelper(
    showPointLightHelper && pointLightRef,
    PointLightHelper,
    Math.max(pointLightDistance, 0.5),
    'blue'
  );

  // useEffect(() => {
  //   // console.log('🚀 ~ camera:', camera);
  //   // console.log('🚀 ~ useEffect ~ camera:', camera.position.z);
  //   // console.log('🚀 ~ useEffect ~ cameraZ:', cameraZ);
  //   const depthRange = camera.far - camera.near;
  //   const { z: cameraZ } = camera.position;
  //   const targetDepth = cameraZ - camera.near;
  //   const normalisedZ = targetDepth / depthRange;
  //   // console.log('🚀 ~ useEffect ~ normalisedZ:', normalisedZ);

  //   const worldPoint = new Vector3(-0.5, 0.5, targetDepth).unproject(camera);
  //   // console.log('🚀 ~ useEffect ~ worldPoint:', worldPoint);
  //   // const worldPoint = screenPoint.
  //   // console.log('🚀 ~ useEffect ~ screenPoint:', screenPoint.unproject(camera));

  //   // sphereRef.current.position.set(worldPoint.x, worldPoint.y, worldPoint.z);
  // }, [camera, pointer, viewport]);

  useFrame(({ pointer, clock, viewport, raycaster, camera, scene }, delta) => {
    if (!pointLightContainerRef.current || !open) {
      return;
    }

    raycaster.setFromCamera(pointer, camera);
    const intersects = raycaster.intersectObject(sphereRef.current);

    const { elapsedTime } = clock;

    let nextLightPosition = new Vector2(
      pointLightContainerRef.current.position.x,
      pointLightContainerRef.current.position.y
    );

    if (pointerPositionRef.current.equals(pointer)) {
      timerRef.current += delta;
    } else {
      timerRef.current = 0;
      pointerPositionRef.current = pointer.clone();
    }

    let nextPosX = 0;
    let nextPosY = 0;
    let strength = 1;

    if (Math.floor(timerRef.current) > 5) {
      nextPosX = MathUtils.mapLinear(
        Math.cos(elapsedTime * 0.5),
        -1,
        1,
        xOffset + -viewport.width * 0.15,
        xOffset + viewport.width * 0.15
      );

      nextPosY = MathUtils.mapLinear(
        noise(1, 1, elapsedTime * 0.025),
        -1,
        1,
        -viewport.height * 0.15,
        viewport.height * 0.15
      );

      strength = 0.075;
    } else {
      if (!intersects.length) {
        return;
      }

      const intersect = intersects[0];
      let { point } = intersect;

      nextPosX = point.x;
      nextPosY = point.y;

      strength = 0.1;
    }

    nextLightPosition.lerp(new Vector2(nextPosX, nextPosY), strength);

    pointLightContainerRef.current.position.set(
      nextLightPosition.x,
      nextLightPosition.y,
      pointLightContainerRef.current.position.z
    );
  });

  return (
    <>
      {showAxes && <axesHelper />}
      {showGrid && <gridHelper />}
      {enableOrbitControls && <OrbitControls />}

      <ambientLight
        color={debug ? ambientColor : texture.config?.ambientColor || '#fff'}
        intensity={
          debug ? ambientIntensity : texture.config?.ambientIntensity || 3
        }
      />

      <group ref={pointLightContainerRef} position={[0, 0, 1]}>
        <pointLight
          ref={pointLightRef}
          color={
            debug
              ? pointLightColor
              : texture.config?.pointLightColor || '#e2e1c1'
          }
          position={[0, 0, -0.8]}
          intensity={
            debug ? pointIntensity : texture.config?.pointLightIntensity || 4
          }
          decay={debug ? pointLightDecay : texture.config?.pointLightDecay || 2}
          distance={
            debug ? pointLightDistance : texture.config?.pointLightDistance || 0
          }
        />
      </group>

      <mesh ref={sphereRef} name="hit-space" position-z={-1}>
        {/* <planeGeometry /> */}
        <planeGeometry args={[10, 10]} />
        <meshBasicMaterial transparent opacity={0} color="#9532a8" />
      </mesh>

      <ImagePlane
        open={open}
        texture={debug ? TEXTURES[debugTexture] : texture}
        centerPoint={centerPoint}
        xOffset={xOffset}
        debug={debug}
      />
    </>
  );
}
