import coin from '../assets/objects/1.png';
import necklace from '../assets/objects/2.png';
import amulet from '../assets/objects/3.png';
import silver from '../assets/objects/4.png';
import horse from '../assets/objects/5.png';
import dagger from '../assets/objects/6.png';
import ring from '../assets/objects/7.png';
import spear from '../assets/objects/8.png';
import bird from '../assets/objects/9.png';

type Artifact = {
  name: string;
  origin: string;
  image: string;
};

const artifacts: Artifact[] = [
  {
    name: 'Sølmynt',
    origin: 'Melhus',
    image: coin,
  },
  {
    name: 'Halskjede',
    origin: 'Århus',
    image: necklace,
  },
  {
    name: 'Amulett',
    origin: 'Stavanger',
    image: amulet,
  },
  {
    name: 'Sølvmynt',
    origin: 'Stjørdal',
    image: silver,
  },
  {
    name: 'Hestebrosje',
    origin: 'Århus',
    image: horse,
  },
  {
    name: 'Øksespiss',
    origin: 'Stavanger',
    image: dagger,
  },
  {
    name: 'Gullring',
    origin: 'Stjørdal',
    image: ring,
  },
  {
    name: 'Spydspiss',
    origin: 'Alta',
    image: spear,
  },
  {
    name: 'Brosje',
    origin: 'Bodø',
    image: bird,
  },
];

export { artifacts };
export type { Artifact };
