import {
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  useCallback,
} from 'react';
import classNames from 'classnames';
import { MdOutlineAdd } from 'react-icons/md';
import { useProgress } from '@react-three/drei';
import { motion, AnimatePresence } from 'framer-motion';

import { colors, Color } from '../../data/colors';
import { artifacts, Artifact } from '../../data/artifacts';
import { TEXTURES, PlaneTexture } from '../../data/textures';

import { Canvas } from '../../components/Canvas/Canvas';
import { ProgressIndicator } from '../../components/Progress/Progress';

import vLetter from '../../assets/logo/v.svg';
import iLetter from '../../assets/logo/i.svg';
import mLetter from '../../assets/logo/m.svg';
import uLetter from '../../assets/logo/u.svg';
import vLetterW from '../../assets/logo/v_w.svg';
import iLetterW from '../../assets/logo/i_w.svg';
import mLetterW from '../../assets/logo/m_w.svg';
import uLetterW from '../../assets/logo/u_w.svg';

import logo from '../../assets/logo.svg';
import logoW from '../../assets/logo_w.svg';
import branding from '../../assets/ntnu_logo.svg';
import brandingW from '../../assets/ntnu_logo_w.svg';

import styles from './Generator.module.scss';

const logos = [
  logo,
  logoW,
  branding,
  brandingW,
  vLetter,
  iLetter,
  mLetter,
  uLetter,
  vLetterW,
  iLetterW,
  mLetterW,
  uLetterW,
];

type Props = {
  animationTime?: number;
};

function App({ animationTime = 1000 }: Props) {
  const textureContainerRef = useRef<HTMLDivElement | null>(null);
  const [, setLoadedAssets] = useState<boolean>(false);
  const [artifact, setArtifact] = useState<Artifact | null>(null);
  const [texture, setTexture] = useState<PlaneTexture>(
    Object.values(TEXTURES)[2]
  );

  const [preloadComplete, setPrloadComplete] = useState<boolean>(false);
  const progress = useProgress();

  useEffect(() => {
    if (!progress.active && progress.progress && !preloadComplete) {
      setTimeout(() => setPrloadComplete(true), 500);
    }
  }, [progress]);

  const [started, setStarted] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [animating, setAnimating] = useState<boolean>(false);
  const [place, setPlace] = useState<number>(0);
  const [color, setColor] = useState<Color>({ light: true, hex: '#ffd200' });
  const [centerPoint, setCenterPoint] = useState({
    x: 0,
    y: 0,
  });

  const setNewArtifact = useCallback(() => {
    const getNewRandomNumber = (
      currentNumber: number,
      size: number
    ): number => {
      let newRandomNumber = Math.floor(Math.random() * size);
      if (newRandomNumber === currentNumber) {
        return getNewRandomNumber(currentNumber, size);
      } else {
        return newRandomNumber;
      }
    };

    const getNextItem = (array: any[], currentItem: any): any => {
      const currentIndex = array.indexOf(currentItem);
      return array[(currentIndex + 1) % array.length];
    };

    const getNewRandomItem = (array: any[], currentItem: any): any => {
      let newArtifact = array[Math.floor(Math.random() * (array.length - 1))];
      if (
        JSON.stringify(newArtifact) === JSON.stringify(currentItem) &&
        array.length > 1
      ) {
        return getNewRandomItem(array, currentItem);
      } else {
        return newArtifact;
      }
    };

    setPlace(getNewRandomNumber(place, 3));
    setArtifact(getNextItem(artifacts, artifact));
    setTexture(getNextItem(Object.values(TEXTURES), texture));
    setColor(getNewRandomItem(colors, color));
    setOpen(true);
    setTimeout(() => {
      setAnimating(false);
    }, animationTime);
  }, [animationTime, artifact, color, place, texture]);

  const updateArtifact = useCallback(() => {
    if (!animating) {
      setOpen(false);
      setAnimating(true);
      if (started) {
        setTimeout(() => {
          setNewArtifact();
        }, animationTime);
      } else {
        setNewArtifact();
      }
    }
  }, [animating, animationTime, setNewArtifact, started]);

  useEffect(() => {
    const spacePress = (e: KeyboardEvent) => {
      if (e.code === 'Space' && open) {
        updateArtifact();
      }
    };
    window.addEventListener('keydown', spacePress);

    return () => {
      window.removeEventListener('keydown', spacePress);
    };
  }, [updateArtifact, open]);

  const clearIntro = useCallback(() => {
    setStarted(true);
    updateArtifact();
  }, [updateArtifact]);

  useEffect(() => {
    let isCancelled = false;
    async function effect() {
      if (isCancelled) {
        return;
      }
      const imagesPromiseList: Promise<any>[] = [];

      // artifacts.forEach((artifact) => {
      //   imagesPromiseList.push(preloadImage(artifact.image));
      // });

      logos.forEach((logo) => {
        imagesPromiseList.push(preloadImage(logo));
      });

      await Promise.all(imagesPromiseList);
      if (isCancelled) {
        return;
      }
      setLoadedAssets(true);
    }
    effect();
    return () => {
      isCancelled = true;
    };
  }, []);

  const calculateCenterPoint = () => {
    if (textureContainerRef.current) {
      const { left, height, top, width } =
        textureContainerRef.current?.getBoundingClientRect();

      const position = {
        x: Math.floor(Number(left) + Number(width) * 0.5),
        y: Math.floor(Number(top) + Number(height) * 0.5),
      };

      setCenterPoint(position);
    }
  };

  useLayoutEffect(() => {
    calculateCenterPoint();
  }, [open]);

  useEffect(() => {
    window.addEventListener('resize', calculateCenterPoint);

    return () => {
      window.removeEventListener('resize', calculateCenterPoint);
    };
  }, []);

  function preloadImage(src: string) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve(img);
      };
      img.onerror = img.onabort = function () {
        reject(src);
      };
      img.src = src;
    });
  }

  return (
    <main
      className={classNames(
        styles.app,
        { [styles.light]: color.light },
        { [styles.started]: started }
      )}
      style={{ backgroundColor: color.hex }}
    >
      <header className={styles.header}>
        <img
          className={styles.logo}
          src={color.light ? logo : logoW}
          alt="Vitenskapsmuseet"
        />
        <div className={styles.instructions}>
          <span className={styles.tutorial}>
            Press space or click to explore the collection
          </span>
          <button className={styles.button} onClick={updateArtifact}>
            <MdOutlineAdd />
          </button>
        </div>
        <img
          className={styles.branding}
          src={color.light ? branding : brandingW}
          alt="NTNU"
        />
      </header>

      <div className={styles.canvasContainer}>
        {preloadComplete && (
          <Canvas
            open={open}
            centerPoint={centerPoint}
            texture={texture}
            color={color.hex}
          />
        )}
      </div>

      <section className={styles.visual}>
        <img
          className={styles.letter}
          src={color.light ? vLetter : vLetterW}
          alt="v"
        />
        <img
          className={styles.letter}
          src={color.light ? iLetter : iLetterW}
          alt="i"
        />
        <img
          className={styles.letter}
          src={color.light ? mLetter : mLetterW}
          alt="m"
        />
        <img
          className={styles.letter}
          src={color.light ? uLetter : uLetterW}
          alt="u"
        />

        <div
          ref={textureContainerRef}
          className={classNames(styles.artifact, { [styles.open]: open })}
          style={{
            order: place + 1,
            transitionDuration: `${animationTime}ms`,
          }}
        >
          {/* <img
            className={styles.image}
            src={artifact ? artifact.image : ''}
            alt=""
          /> */}
        </div>
        {/* {!preloadComplete && ( */}
        {/* )} */}
      </section>

      <AnimatePresence>
        {!preloadComplete && (
          <motion.div
            key="progress-indicator"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.progressContainer}
          >
            <ProgressIndicator />
          </motion.div>
        )}
      </AnimatePresence>

      <footer className={styles.footer}>
        <section className={styles.info}>
          <div className={styles.meta}>
            <div className={styles.title}>Object</div>
            <div className={styles.value}>{texture && texture.name}</div>
          </div>
          <div className={styles.meta}>
            <div className={styles.title}>Period</div>
            <div className={styles.value}>{texture && texture.period}</div>
          </div>
          <div className={styles.meta}>
            <div className={styles.title}>Material</div>
            <div className={styles.value}>{texture && texture.material}</div>
          </div>
          <div className={styles.meta}>
            <div className={styles.title}>Location</div>
            <div className={styles.value}>{texture && texture.location}</div>
          </div>
        </section>
        <button className={styles.button} onClick={updateArtifact}>
          <MdOutlineAdd />
        </button>

        <section className={styles.link}>
          <div className={styles.meta}>
            <div className={styles.title}>Buy tickets at :</div>
            <a
              className={styles.value}
              href={'https://www.ntnu.no/museum'}
              target="_blank"
              rel="noreferrer"
            >
              ntnu.no/museum
            </a>
          </div>
        </section>
      </footer>

      <section className={styles.overlay}>
        {preloadComplete && (
          <motion.div
            key="landing-page-indicator"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.titleContainer}
          >
            <div className={styles.title}>
              Open up our collection to explore a world of artifacts
            </div>
            <button className={styles.activate} onClick={clearIntro}>
              Open
            </button>
          </motion.div>
        )}
      </section>
    </main>
  );
}

export default App;
