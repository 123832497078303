import { useEffect, useState } from 'react';
import { Leva } from 'leva';

import { useKeyPress } from '../../hooks/useKeyPress';

export function LevaConfig() {
  const [isHidden, setIsHidden] = useState(true);

  const vIsPressed = useKeyPress('v');
  const mIsPressed = useKeyPress('m');

  useEffect(() => {
    console.log(`Press 'v' and 'm' to toggle debug panel.`);
  }, []);

  useEffect(() => {
    if (vIsPressed && mIsPressed) {
      setIsHidden(!isHidden);
    }
  }, [vIsPressed, mIsPressed]);

  return <Leva collapsed hidden={isHidden} />;
}
