import React, { useReducer, createContext } from 'react';

type Props = {
  readonly children?: React.ReactNode;
};

type Settings = {
  version: number;
};

type Actions = UpdateVersion;

type UpdateVersion = {
  type: 'update_version';
  payload: number;
};

type Context = {
  settings: Settings;
  dispatch: React.Dispatch<Actions>;
};

const initialSettings = (): Settings => {
  return {
    version: 1,
  };
};

const reducer = (settings: Settings, action: Actions): Settings => {
  switch (action.type) {
    case 'update_version':
      const updatedWidth: Settings = {
        ...settings,
        version: action.payload,
      };
      return updatedWidth;

    default:
      return { ...settings };
  }
};

const SettingsContext = createContext<Context>({
  settings: initialSettings(),
  dispatch: () => {},
});
const SettingsProvider = ({ children }: Props): JSX.Element => {
  const [settings, dispatch] = useReducer(reducer, initialSettings());

  return (
    <SettingsContext.Provider value={{ settings, dispatch }}>
      {children}
    </SettingsContext.Provider>
  );
};

export { SettingsContext, SettingsProvider };
