import { useProgress } from '@react-three/drei';
import * as Progress from '@radix-ui/react-progress';
import styles from './Progress.module.scss';

export function ProgressIndicator() {
  const { progress } = useProgress();

  return (
    <Progress.Root className={styles.ProgressRoot} value={progress}>
      <Progress.Indicator
        className={styles.ProgressIndicator}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
      <span>{progress}</span>
    </Progress.Root>
  );
}
