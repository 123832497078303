import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Generator from './pages/Generator/Generator';
import { SettingsProvider } from './contexts/settings';
import { LevaConfig } from './components/LevaConfig/LevaConfig';

const App = () => {
  return (
    <SettingsProvider>
      <LevaConfig />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Generator />} />
        </Routes>
      </BrowserRouter>
    </SettingsProvider>
  );
};

export default App;
