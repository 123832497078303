import { Vector2 } from 'three';

export type TextureConfig = {
  metalness?: number;
  roughness?: number;
  rotationX?: number;
  rotationY?: number;
  ambientIntensity?: number;
  ambientColor?: string;
  pointLightIntensity?: number;
  pointLightColor?: string;
  pointLightDecay?: number;
  pointLightDistance?: number;
  displacementScale?: number;
  normalScale?: Vector2;
};

export type PlaneTexture = {
  name: string;
  period: string;
  material: string;
  location: string;
  map: string;
  pred: string;
  pred_colored: string;
  normals: string;
  height: string;
  config?: TextureConfig;
};

type TexturesCollection = {
  [key: string]: PlaneTexture;
};

const defaultConfig = {
  ambientIntensity: 14,
  ambientColor: '#fff',
  pointLightIntensity: 1.5,
  pointLightColor: '#e2e1c1',
  pointLightDistance: 0.5,
  pointLightDecay: 2,
  displacementScale: 0.15,
  metalness: 0.75,
  roughness: 0.5,
  rotationX: 0.15,
  rotationY: 0.15,
};

export const TEXTURES: TexturesCollection = {
  // Aglais_io_Foto_Hallvard_Elven: {
  //   name: 'Sølmynt',
  //   pred: 'Aglais_io_Foto_Hallvard_Elven_pred.png',
  //   pred_colored: 'Aglais_io_Foto_Hallvard_Elven_pred_colored.png',
  //   map: 'Aglais_io_Foto_Hallvard_Elven.png',
  //   height: 'Aglais_io_Foto_Hallvard_Elven_height.png',
  //   normals: 'Aglais_io_Foto_Hallvard_Elven_normals.png',
  // },
  N20190: {
    name: 'Horse',
    period: 'Medieval Period/modern',
    material: 'Wood',
    location: 'Trondheim, Trøndelag',
    pred: 'N20190_pred.png',
    pred_colored: 'N20190_pred_colored.png',
    map: 'N20190.png',
    height: 'N20190_height.png',
    normals: 'N20190_normals.png',
    config: {
      ...defaultConfig,
      pointLightColor: '#d5d1aa',
      rotationX: 0.25,
    },
  },
  T1082: {
    name: 'Lavabo',
    period: 'Medieval Period',
    material: 'Brass',
    location: 'Nordland',
    pred: 'T1082_pred.png',
    pred_colored: 'T1082_pred_colored.png',
    map: 'T1082.png',
    height: 'T1082_height.png',
    normals: 'T1082_normals.png',
    config: {
      ...defaultConfig,
      pointLightColor: '#d6c3ab',
      // ambientIntensity: 3.0,
      // pointLightIntensity: 4.6,
      // pointLightDecay: 3.5,
      // displacementScale: 0.32,
      // metalness: 0.53,
      // roughness: 0.44,
      // rotationX: 0.06,
      // rotationY: 0.13,
    },
  },
  T1266: {
    name: 'Dagger',
    period: 'Early Bronze Age',
    material: 'Bronze',
    location: 'Steinkjer, Trøndelag',
    pred: 'T1266_pred.png',
    pred_colored: 'T1266_pred_colored.png',
    map: 'T1266.png',
    height: 'T1266_height.png',
    normals: 'T1266_normals.png',
    config: {
      ...defaultConfig,
      pointLightColor: '#76b3d1',
      // ambientIntensity: 3.2,
      // pointLightIntensity: 4.2,
      // pointLightDistance: 0,
      // pointLightDecay: 1.5,
      // displacementScale: 0.47,
      // metalness: 0.61,
      // roughness: 0.31,
      rotationX: 0.31,
      // rotationY: 0.15,
    },
  },
  T13890: {
    name: 'Finger Ring',
    period: 'Medieval Period',
    material: 'Silver/glass Enamel',
    location: 'Trondheim, Trøndelag',
    pred: 'T13890_pred.png',
    pred_colored: 'T13890_pred_colored.png',
    map: 'T13890.png',
    height: 'T13890_height.png',
    normals: 'T13890_normals.png',
    config: {
      ...defaultConfig,
      pointLightColor: '#e8ded4',
      // ambientIntensity: 9,
      // pointLightIntensity: 8.9,
      // pointLightDistance: 0,
      // pointLightDecay: 1.5,
      // displacementScale: 0.3,
      // metalness: 0.6,
      // roughness: 0.7,
      rotationY: 0.05,
      // rotationX: 0.05,
    },
  },
  T14495: {
    name: 'Axe',
    period: 'Viking Period/Early Medieval Period',
    material: 'Iron',
    location: 'Oppdal, Trøndelag',
    pred: 'T14495_pred.png',
    pred_colored: 'T14495_pred_colored.png',
    map: 'T14495.png',
    height: 'T14495_height.png',
    normals: 'T14495_normals.png',
    config: {
      ...defaultConfig,
      metalness: 0.9,
      ambientIntensity: 10,
      // pointLightIntensity: 4.2,
      // pointLightDistance: 0,
      // pointLightDecay: 1.5,
      // pointLightColor: '#cbad63',
      displacementScale: 0.15,
      // metalness: 0.39,
      // roughness: 0.37,
      // rotationX: 0.22,
      // rotationY: 0.22,
    },
  },
  T14530: {
    name: 'Axe',
    period: 'Neolithic/Bronze Age',
    material: 'Rock',
    location: 'Vevelstad, Nordland',
    pred: 'T14530_pred.png',
    pred_colored: 'T14530_pred_colored.png',
    map: 'T14530.png',
    height: 'T14530_height.png',
    normals: 'T14530_normals.png',
    config: {
      ...defaultConfig,
      // ambientIntensity: 2.9,
      // pointLightIntensity: 9.4,
      // pointLightDistance: 0,
      // pointLightDecay: 2.0,
      pointLightColor: '#d1c3bc',
      // displacementScale: 0.47,
      // metalness: 0.37,
      // roughness: 0.55,
      // rotationX: 0.22,
      // rotationY: 0.22,
    },
  },
  T1504: {
    name: 'Seal',
    period: 'High Medieval Period',
    material: 'Bronze',
    location: 'Trondheim, Trøndelag',
    pred: 'T1504_pred.png',
    pred_colored: 'T1504_pred_colored.png',
    map: 'T1504.png',
    height: 'T1504_height.png',
    normals: 'T1504_normals.png',
    config: {
      ...defaultConfig,
      ambientIntensity: 12,
      // pointLightIntensity: 4.0,
      // pointLightDistance: 0,
      // pointLightDecay: 1.0,
      pointLightColor: '#c79796',
      // displacementScale: 0.35,
      metalness: 0.85,
      // roughness: 0.43,
      // rotationX: 0.15,
      // rotationY: 0.15,
    },
  },
  T15327: {
    name: 'Figurine',
    period: 'Medieval Period',
    material: 'Bronze',
    location: 'Brønnøy, Nordland',
    pred: 'T15327_pred.png',
    pred_colored: 'T15327_pred_colored.png',
    map: 'T15327.png',
    height: 'T15327_height.png',
    normals: 'T15327_normals.png',
    config: {
      ...defaultConfig,
      ambientIntensity: 15,
      pointLightIntensity: 4,
      pointLightColor: '#d1b777',
      // pointLightDecay: 3.5,
      // displacementScale: 0.32,
      metalness: 0.8,
      // roughness: 0.44,
      rotationX: 0.2,
      rotationY: 0.2,
    },
  },
  T1715: {
    name: 'Bead',
    period: 'Iron Age',
    material: 'Glass',
    location: 'Indre Fosen, Trøndelag',
    pred: 'T1715_pred.png',
    pred_colored: 'T1715_pred_colored.png',
    map: 'T1715.png',
    height: 'T1715_height.png',
    normals: 'T1715_normals.png',
    config: {
      ...defaultConfig,
      // ambientIntensity: 2.5,
      // pointLightIntensity: 10,
      pointLightColor: '#e2d9b2',
      // pointLightDistance: 0,
      // pointLightDecay: 2.0,
      // displacementScale: 0.32,
      // metalness: 0.59,
      // roughness: 0.72,
      // rotationX: 0.2,
      // rotationY: 0.2,
    },
  },
  T18842_1: {
    name: 'Golden men',
    period: 'Early/late Iron Age',
    material: 'Gold',
    location: 'Steinkjer, Trøndelag',
    pred: 'T18842_1_pred.png',
    pred_colored: 'T18842_1_pred_colored.png',
    map: 'T18842_1.png',
    height: 'T18842_1_height.png',
    normals: 'T18842_1_normals.png',
    config: {
      ...defaultConfig,
      ambientIntensity: 13,
      pointLightIntensity: 3.5,
      pointLightColor: '#96836c',
      pointLightDistance: 1.0,
      displacementScale: 0.2,
      // pointLightDecay: 1.5,
      metalness: 0.9,
      // roughness: 0.27,
      // rotationX: 0.2,
      // rotationY: 0.2,
    },
  },
  T24719_297: {
    name: 'Coin',
    period: 'Medieval Period/modern',
    material: 'Silver',
    location: 'Alstadhaug, Nordland',
    pred: 'T24719_297_pred.png',
    pred_colored: 'T24719_297_pred_colored.png',
    map: 'T24719_297.png',
    height: 'T24719_297_height.png',
    normals: 'T24719_297_normals.png',
    config: {
      ...defaultConfig,
      // ambientIntensity: 4.5,
      // pointLightIntensity: 9,
      pointLightColor: '#e2d9b2',
      // pointLightDistance: 0,
      // pointLightDecay: 1.5,
      // displacementScale: 0.41,
      // metalness: 0.59,
      // roughness: 0.27,
      // rotationX: 0.2,
      // rotationY: 0.2,
    },
  },
  T25523_262: {
    name: 'Coin',
    period: '1660',
    material: 'Silver',
    location: 'Utrecht',
    pred: 'T25523_262_pred.png',
    pred_colored: 'T25523_262_pred_colored.png',
    map: 'T25523_262.png',
    height: 'T25523_262_height.png',
    normals: 'T25523_262_normals.png',
    config: {
      ...defaultConfig,
      pointLightColor: '#ceb894',
      displacementScale: 0.15,
      metalness: 0.85,
      // metalness: 0.56,
      // roughness: 0.39,
      // rotationX: 0.3,
      // rotationY: 0.3,
      // ambientIntensity: 1.9,
      // pointLightIntensity: 7.0,
    },
  },
  T2589: {
    name: 'Weight',
    period: 'Medieval Period',
    material: 'Bronze',
    location: 'Stjørdal, Trøndelag',
    pred: 'T2589_pred.png',
    pred_colored: 'T2589_pred_colored.png',
    map: 'T2589.png',
    height: 'T2589_height.png',
    normals: 'T2589_normals.png',
    config: {
      ...defaultConfig,
      metalness: 0.85,
      roughness: 0.4,
      rotationX: 0.15,
      rotationY: 0.15,
      // ambientIntensity: 4.5,
      // pointLightIntensity: 8.4,
      pointLightColor: '#cfcaa1',
      displacementScale: 0.2,
    },
  },
  T26661: {
    name: 'Cross',
    period: 'Medieval Period',
    material: 'Lead',
    location: 'Stjørdal, Trøndelag',
    pred: 'T26661_pred.png',
    pred_colored: 'T26661_pred_colored.png',
    map: 'T26661.png',
    height: 'T26661_height.png',
    normals: 'T26661_normals.png',
    config: {
      ...defaultConfig,
      // metalness: 0.85,
      // roughness: 0.4,
      // ambientIntensity: 2.2,
      // pointLightIntensity: 8.2,
      // pointLightDistance: 2.5,
      // pointLightDecay: 2.0,
      // displacementScale: 0.28,
      pointLightColor: '#e8c58d',
      rotationX: 0.8,
      rotationY: 0.15,
    },
  },
  T362: {
    name: 'Pot',
    period: 'Late Roman Period',
    material: 'Clay',
    location: 'Melhus, Trøndelag',
    pred: 'T362_pred.png',
    pred_colored: 'T362_pred_colored.png',
    map: 'T362.png',
    height: 'T362_height.png',
    normals: 'T362_normals.png',
    config: {
      ...defaultConfig,
      pointLightColor: '#a3a187',
      // ambientIntensity: 1.9,
      // pointLightIntensity: 9.5,
      // pointLightDistance: 0,
      // pointLightDecay: 8.0,
      // displacementScale: 0.36,
      // metalness: 0.36,
      // roughness: 0.67,
      // rotationX: 0.1,
      // rotationY: 0.1,
    },
  },
  T3711: {
    name: 'Jar',
    period: 'Medieval Period',
    material: 'Clay',
    location: 'Trondheim, Trøndelag',
    pred: 'T3711_pred.png',
    pred_colored: 'T3711_pred_colored.png',
    map: 'T3711.png',
    height: 'T3711_height.png',
    normals: 'T3711_normals.png',
    config: {
      ...defaultConfig,
      pointLightColor: '#dbdbdb',
      // ambientIntensity: 1.4,
      // pointLightIntensity: 7.5,
      // pointLightDistance: 0,
      // pointLightDecay: 2.5,
      // displacementScale: 0.26,
      // metalness: 0.1,
      // roughness: 0.5,
      // rotationX: 0.3,
      // rotationY: 0.15,
    },
  },
  T374: {
    name: 'Finger Ring',
    period: 'Late Roman Period',
    material: 'Gold',
    location: 'Melhus, Trøndelag',
    pred: 'T374_pred.png',
    pred_colored: 'T374_pred_colored.png',
    map: 'T374.png',
    height: 'T374_height.png',
    normals: 'T374_normals.png',
    config: {
      ...defaultConfig,
      pointLightColor: '#afcad8',
      // ambientIntensity: 4.9,
      // pointLightIntensity: 11.9,
      // pointLightDistance: 0,
      // pointLightDecay: 4.5,
      // displacementScale: 0.42,
      // metalness: 0.47,
      // roughness: 0.33,
      // rotationX: 0.1,
      // rotationY: 0.1,
    },
  },
  T4012: {
    name: 'Spearhead',
    period: 'Bronze Age',
    material: 'Bronze',
    location: 'Møre Og Romsdal',
    pred: 'T4012_pred.png',
    pred_colored: 'T4012_pred_colored.png',
    map: 'T4012.png',
    height: 'T4012_height.png',
    normals: 'T4012_normals.png',
    config: {
      ...defaultConfig,
      pointLightColor: '#d9c8b0',
      // ambientIntensity: 4.9,
      // pointLightIntensity: 6.7,
      // pointLightDistance: 0,
      // pointLightDecay: 6.0,
      // displacementScale: 0.53,
      // metalness: 0.47,
      // roughness: 0.38,
      // rotationX: 0.22,
      // rotationY: 0.14,
    },
  },
  T420: {
    name: 'Jewellery',
    period: 'High Medieval Period',
    material: 'Gold',
    location: 'Steinkjer, Trøndelag',
    pred: 'T420_pred.png',
    pred_colored: 'T420_pred_colored.png',
    map: 'T420.png',
    height: 'T420_height.png',
    normals: 'T420_normals.png',
    config: {
      ...defaultConfig,
      pointLightColor: '#b0d3d9',
      // ambientIntensity: 7.5,
      // pointLightIntensity: 8.5,
      // pointLightDistance: 3,
      // pointLightDecay: 1.0,
      // displacementScale: 0.53,
      // metalness: 0.66,
      // roughness: 0.49,
      // rotationX: 0.15,
      // rotationY: 0.15,
    },
  },
  T5171: {
    name: 'Headgear',
    period: 'Medieval Period/modern',
    material: 'Textile',
    location: 'Stjørdal, Trøndelag',
    pred: 'T5171_pred.png',
    pred_colored: 'T5171_pred_colored.png',
    map: 'T5171.png',
    height: 'T5171_height.png',
    normals: 'T5171_normals.png',
    config: {
      ...defaultConfig,
      pointLightColor: '#bfd2d6',
      // ambientIntensity: 3.2,
      // pointLightIntensity: 9.3,
      // pointLightDistance: 2.5,
      // pointLightDecay: 1.0,
      // displacementScale: 0.43,
      // metalness: 0.2,
      // roughness: 0.6,
      // rotationX: 0.15,
      // rotationY: 0.1,
    },
  },
  // T647: {
  //   name: 'Figurine',
  //   period: 'Medieval Period',
  //   material: 'Wood',
  //   location: 'Trondheim, Trøndelag',
  //   pred: 'T647_pred.png',
  //   pred_colored: 'T647_pred_colored.png',
  //   map: 'T647.png',
  //   height: 'T647_height.png',
  //   normals: 'T647_normals.png',
  //   config: {
  //     ambientIntensity: 7.5,
  //     pointLightIntensity: 9.3,
  //     pointLightDistance: 2.5,
  //     pointLightDecay: 1.0,
  //     pointLightColor: '#bfd2d6',
  //     displacementScale: 0.43,
  //     metalness: 0.7,
  //     roughness: 0.53,
  //     rotationX: 0.15,
  //     rotationY: 0.4,
  //   },
  // },
  // T67000: {
  //   name: 'Sword',
  //   period: 'Viking Period',
  //   material: 'Bronze/iron',
  //   location: 'Skaun, Trøndelag',
  //   pred: 'T67000_pred.png',
  //   pred_colored: 'T67000_pred_colored.png',
  //   map: 'T67000.png',
  //   height: 'T67000_height.png',
  //   normals: 'T67000_normals.png',
  //   config: {
  //     ambientIntensity: 7.8,
  //     pointLightIntensity: 12,
  //     pointLightDistance: 0,
  //     pointLightDecay: 2.0,
  //     pointLightColor: '#ded8b1',
  //     displacementScale: 0.3,
  //     metalness: 0.66,
  //     roughness: 0.34,
  //     rotationX: 0.3,
  //     rotationY: 0.4,
  //   },
  // },
  // T897: {
  //   name: 'Figurine',
  //   period: 'High Medieval Period',
  //   material: 'Wood',
  //   location: 'Steinkjer, Trøndelag',
  //   pred: 'T897_pred.png',
  //   pred_colored: 'T897_pred_colored.png',
  //   map: 'T897.png',
  //   height: 'T897_height.png',
  //   normals: 'T897_normals.png',
  //   config: {
  //     ambientIntensity: 10.5,
  //     pointLightIntensity: 5.6,
  //     pointLightDistance: 0,
  //     pointLightDecay: 1.0,
  //     pointLightColor: '#ded8b1',
  //     displacementScale: 0.47,
  //     metalness: 0.81,
  //     roughness: 0.52,
  //     rotationX: 0.13,
  //     rotationY: 0.36,
  //   },
  // },
  T9261: {
    name: 'Key',
    period: 'Viking Period',
    material: 'Bronze',
    location: 'Tingvoll, Trøndelag',
    pred: 'T9261_pred.png',
    pred_colored: 'T9261_pred_colored.png',
    map: 'T9261.png',
    height: 'T9261_height.png',
    normals: 'T9261_normals.png',
    config: {
      ...defaultConfig,
      pointLightColor: '#e8ded4',
      // ambientIntensity: 4.4,
      // pointLightIntensity: 7,
      // pointLightDistance: 2,
      // pointLightDecay: 1.5,
      // displacementScale: 0.3,
      // metalness: 0.62,
      // roughness: 0.49,
      // rotationX: 0.2,
      // rotationY: 0.36,
    },
  },
};
